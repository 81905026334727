import React, { useCallback, useState } from 'react';
import { AngularContext } from 'react-app';
import { QrveyConfig, QrveyPanel, QrveyState, questionSetkeyMapping } from 'redux/schemas/models/course';
import { useAppDispatch } from 'redux/store';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { addAlertMessage } from 'redux/actions/alert-messages';
import t from 'react-translate';
import useScript from 'shared/hooks/use-script';
import useLoadToken from 'shared/hooks/use-load-token';
import { useSelector } from 'react-redux';
import { AnalyticsDashBoardType } from 'redux/schemas/app/analytics';
import { each } from 'underscore';
import { useQrveyInitializer, resetQrveyValues } from 'shared/hooks/use-initialize-qrvey';
import { AnalyticsTokenProps } from './analytics-dashboard';
import AnalyticsLoader from './analytics-loader';
import { baseStyles, cohortStyles, courseStyles, institutionStyles, journeyStyles, questionSetStyles, skillsStyles } from './custom-styles';
import { config } from '../../../config/config.json';
import QrveyPanels from './qrvey-panels';

export type QrveyProps = {
  data?: QrveyState;
  type: AnalyticsDashBoardType;
  configKeyName?: string;
  pageIdKey?: string;
  questionSetId?: number
  lecturePageId?: number
};

const QrveyComponent = (props: QrveyProps) => {
  const { type, pageIdKey, questionSetId, lecturePageId } = props;
  let { configKeyName = 'config' } = props;
  const dispatch = useAppDispatch();
  const institutionId = useSelector((state) => state.app.currentInstitutionId);
  const isLoading = useSelector(state => state.app.qrveyConfig.isLoading);
  const { injectServices } = React.useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const { catalogId, scenarioId } = $state.params || null;

  const [pageTitle, setPageTitle] = useState('');
  const [panels, setPanels] = useState<QrveyPanel[]>();

  if (!config.qrvey?.launcherUrl) {
    dispatch(
      addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.OOPS(),
        message: t.QRVEY.CONFIG_ERROR(),
      }),
    );
  }
  const launcherStatus = useScript(config.qrvey.launcherUrl);
  const params: AnalyticsTokenProps = { type, catalogId, institutionId, pageIdKey, scenarioId, questionSetId };
  const data = useLoadToken(params);
  const isReady: boolean = data && launcherStatus === 'ready';

  const initializeQrvey = (qrveydata: QrveyState) => {
    const { token, appId, userId, host } = qrveydata[type];
    const overwritestyles = `${baseStyles}
    ${type === AnalyticsDashBoardType.LEARNING_JOURNEY ? journeyStyles : ''}
    ${type === AnalyticsDashBoardType.INSTITUTION ? institutionStyles : ''}
    ${(type === AnalyticsDashBoardType.COURSE && pageIdKey === 'skills') ? skillsStyles : ''}
    ${(type === AnalyticsDashBoardType.COURSE && pageIdKey !== 'skills') ? courseStyles : ''}
    ${(type === AnalyticsDashBoardType.COHORTS) ? cohortStyles : ''}
    ${(type === AnalyticsDashBoardType.QUESTION_SET) ? questionSetStyles : ''}
    `;

    if (window[configKeyName]) {
      delete window[configKeyName];
    }

    const configQrvey: QrveyConfig = {
      qv_token: token,
      domain: host,
      app_id: appId,
      user_id: userId,
      customCSSRules: overwritestyles,
      custom_styles: true,
      downloadingBoxText: t.QRVEY.DOWNLOAD_BOX(),
      automaticDownload: false,
      featurePermission: {
        panels: {
          global: {
            hide_CSV: true,
          },
        },
      },
    };

    if (type === AnalyticsDashBoardType.COHORTS) {
      configQrvey.page_id = qrveydata.cohort.pageId;
    }
    if (type === AnalyticsDashBoardType.PRACTICE_ANALYTICS) {
      configQrvey.page_id = qrveydata.scenarioAnalytics?.pageId;
    }
    if (type === AnalyticsDashBoardType.COURSE) {
      configQrvey.page_id = qrveydata.course.pageIds[pageIdKey];
    }
    if (type === AnalyticsDashBoardType.LICENSE) {
      configQrvey.page_id = qrveydata[type].pageId;
      configQrvey.userFilters = qrveydata[type].userFilters;
      // for license analytics, we need a dynamic config name based on the page id.
      configKeyName = `${configKeyName}_${qrveydata[type].pageId}`;
    }
    if (type === AnalyticsDashBoardType.QUESTION_SET) {
      configQrvey.dashboardId = qrveydata[type].topPageId;
      if (qrveydata[type].panels) {
        const questionSetPanels: QrveyPanel[] = [];
        each(qrveydata[type].panels, (panel, index) => {
          window[`anPanelConfig${index}`] = {
            domain: host,
            qv_token: token,
            dashboard_id: qrveydata[type].panelsPageId,
            chart_id: panel.chartId,
            qrveyId: panel.dataSetId,
            user_id: userId,
            app_id: appId,
            type: 'CHART',
            userFilters: panel.userFilters,
            panel: {
              header: {
                visible: true,
                filter: false,
                externalDownload: ['PDF', 'JPG', 'EXCEL', 'CSV SUMMARY'],
                menu: {
                  enable: true,
                  options: [
                    {
                      key: 'download',
                      enable: true,
                      options: [
                        {
                          key: 'jpg',
                          enable: true,
                        },
                        {
                          key: 'pdf',
                          enable: true,
                        },
                        {
                          key: 'csv',
                          enable: false,
                        },
                        {
                          key: 'csv-summary',
                          enable: true,
                        },
                        {
                          key: 'excel',
                          enable: true,
                        },
                      ],
                    },
                  ],
                },
              },
            },
          };
          const questionSetPanel: QrveyPanel = {
            ...panel,
          };
          if (panel.chartType === 'Comparison' || panel.chartType === 'All') {
            questionSetPanel.isFullPanel = true;
            questionSetPanel.title = panel.chartType === 'Comparison'
              ? t.QRVEY.COMPARE_RESULTS()
              : t.QRVEY.ALL_QUESTIONS();
          }
          questionSetPanels[index] = questionSetPanel;
        });
        setPanels(questionSetPanels);
      }
      if (qrveydata[type]?.questionSetTitle) {
        const questionSetType = t.COURSE_ANALYTICS[questionSetkeyMapping[data[type]?.questionSetType]]();
        const title = `${questionSetType}: ${data[type].questionSetTitle}`;
        setPageTitle(title);
      }
    }
    window[configKeyName] = configQrvey;
    resetQrveyValues();
  };

  useQrveyInitializer(data, isReady, isLoading, configKeyName, initializeQrvey);
  const getPageTitle = useCallback(() => {
    const pageTitleSplitted = pageTitle?.split(':');
    return (
      <div
        className='d-flex justify-content-center course-title-l mt-6'
      >
        <span className='font-weight-bolder mr-1'>
          {`${pageTitleSplitted[0]}:`}
        </span>
        {lecturePageId ? (
          <a
            href={$state?.href('lecture-page', { catalogId, id: lecturePageId })}
            className='font-weight-light text-primary'
            target='_blank'
            rel='noreferrer'
          >
            {pageTitleSplitted[1]}
          </a>
        )
          : (
            <span className='font-weight-light'>
              {pageTitleSplitted[1]}
            </span>
          )}
      </div>
    );
  }, [$state, catalogId, lecturePageId, pageTitle]);

  return (
    <>
      {(!isReady || isLoading) && <AnalyticsLoader />}
      {isReady && !isLoading && (
        <>
          {pageTitle && getPageTitle()}
          <qrvey-end-user settings={configKeyName} />
          {!!panels?.length && (
            <QrveyPanels panels={panels} />
          )}
        </>
      )}
    </>
  );
};

export default QrveyComponent;
