import { UploadedFile } from 'redux/schemas/models/file-uploads';
import { LectureComponent, ComponentType } from 'redux/schemas/models/lecture-component';
import { User } from './my-account';
import { ActivityType, ActivityProgress, MinimalActivity } from './activity';
import { PointsConfiguration } from './points-configuration';
import { Comment } from './comment';
import { Communication } from './course-communication';
import { MinimalCourse } from './course';
import { SkillTag, SkillTagging } from './skill-tag';
import { Normalized } from '../normalized';
import { MinimalPracticeFeedbackCriteria } from './video-practice-feedback';

export interface MinAndSecTimeFormat {
  min: number;
  sec: number;
}

export enum RecordingFormat {
  VIDEO = 'video',
  AUDIO = 'audio',
}

export enum SubmissionPrivacySetting {
  RESTRICTED = 'restricted',
  SHARED = 'shared',
}

export enum ScenarioReusabilitySetting {
  REUSABLE = 'reusable',
  NOT_REUSABLE = 'not_reusable',
}

interface CourseUsedIn {
  id: number;
  catalogId: string;
  name: string;
}

interface CollectionUsedIn {
  id: number;
  catalogId: string;
  name: string;
}

export type InsightCriterion = {
  id?: number
  keyphrasesToCover?: string[]
  keyphrasesToAvoid?: string[]
};

export interface VideoPracticeScenario {
  id?: number;
  title: string;
  textPrompt?: string;
  videoPrompt?: UploadedFile;
  recordingLimit: number;
  lastTimePracticed: string;
  totalFeaturedCount: number;
  totalInGalleryCount: number;
  recordingFormat: RecordingFormat;
  privacy: SubmissionPrivacySetting;
  reusable: ScenarioReusabilitySetting;
  createdAt?: string;
  createdInCourse?: CourseUsedIn;
  createdInCollection?: CollectionUsedIn;
  numCoursesUsedIn?: number;
  coursesUsedIn: CourseUsedIn[];
  numCollectionsUsedIn?: number;
  collectionsUsedIn: CollectionUsedIn[];
  coursesUsedInCatalogIds: string[];
  submissionIds?: number[];
  isPracticeAdmin?: boolean; // Is logged in user a Course Admin or Mentor
  hasCourseAdmin?: boolean;
  myPracticeVisitedAt?: string
  skillTags?: SkillTag[]
  skillTagIds: number[]
  skillTaggings?: SkillTagging[]
  skillTaggingIds: number[]
  insightCriterion: InsightCriterion
  enableRequestReview?: boolean
}


export enum VideoPracticeOption {
  ON_SPOT = 'on_spot',
  WHEN_READY = 'when_ready',
}

export enum PracticeAgain {
  PRACTICE_AGAIN = 'practice_again',
}

export type VideoPracticeType = VideoPracticeOption | PracticeAgain;

export type SkillsRating = {
  id: number;
  numReviewesCompleted: number;
  pointsReceived: number;
  leaderboardPoints: number;
  leaderboardRank: number;
  priorLeaderboardRank: number;
  progress: string;
};

export interface PracticeSubmissionComment extends Comment {
  video?: UploadedFile;
  videoTimestamp?: number;
  isUserFirstReview?: boolean;
  publicFeedback?: MinimalPracticeFeedbackCriteria;
  review?: SkillsRating;
}

type MinimalScenario = {
  id: number
  title: string
};

enum Sentiment {
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral',
  POSITIVE = 'positive',
}

export type RateOfSpeech = {
  start: number
  end: number
  words: {
    confidence: number
    end: number
    punctuatedWord: string
    sentiment: Sentiment
    start: number
    word: string
  }[],
  wordsPerMinute: number
};

export type FillerWord = {
  label: string, count: number
};

export enum InsightStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}
export interface VideoPracticeSubmission {
  id: number;
  videoPracticeScenarioId?: number;
  videoFile: UploadedFile;
  numLikes: number;
  numComments: number;
  numViews: number;
  user: User;
  completedAt: string;
  isTeamMember: boolean;
  submitted: boolean;

  activity?: MinimalVideoPracticeActivity;
  isLikedByCurrentUser?: boolean;
  likedUsers?: User[];
  submissionComments?: number[];
  featured?: boolean;

  // Returned in response of Complete Practice Submission
  pointsReceived?: number;
  leaderboardPoints?: number;
  leaderboardRank?: number;
  priorLeaderboardRank?: number;
  teamName?: string;
  userPracticeNumber?: number;
  scenario?: MinimalScenario;
  isRatedByCurrentUser?: boolean
  isViewerMentor?: boolean
  coveredKeyphrases?: string[]
  notAvoidedKeyphrases?: string[]
  rateOfSpeech?: RateOfSpeech[]
  overallSentiment?: {[Key in Sentiment]?: number}
  wordsPerMinute?: number
  transcriptionCompleted?: boolean
  hasTranscription?: boolean
  keyphrasesToCover?: string[]
  keyphrasesToAvoid?: string[]
  hasReviewed?: boolean
  fillerPercentage?: number
  peerAverage?: number
  priorAverage?: number
  fillerWords?: FillerWord[]
  priorFillerWords?: FillerWord[]
  vocalizedPausesCount?: number
  priorVocalizedPausesCount?: number
  fillerInsightStatus?: InsightStatus
  transcriptionInsightsStatus?: InsightStatus
  requestedAt?: string
}

export interface VideoPracticeActivity extends MinimalVideoPracticeActivity {
  id: number;
  maxTries?: number;
  expectations: string;
  videoPracticeOption: VideoPracticeOption;
  submissionId?: number;
  scenarioId?: number;
  submissionsLoaded?: boolean;
  submissionIds?: number[];
  remainingSubmissions?: number;
  customTitle?: string;

  // standard activity attributes
  progress: ActivityProgress;
  isTodo: boolean;
  hardDeadline: boolean;
  deadline: string;
  deadlinePassed: boolean;
  totalPoints: number[];
  pointsReceived: number;
  priorLeaderboardRank: number;
  leaderboardPoints: number;
  leaderboardRank: number;
  isRequiredForCompletion: boolean;
  pointsConfiguration: PointsConfiguration;
  createdAt: string;
  released: boolean;
  user: User;

  catalogId: string;
  courseCatalogId: string,
  courseId: string;

  // course communications
  communications?: Communication[],
  communicationsCount?: number,

  hasSkillsRating?: boolean,
}

export interface MinimalVideoPracticeActivity extends MinimalActivity {
  type: ActivityType.VIDEO_PRACTICE;
  id: number;
  activityType: string;
  course: MinimalCourse;
  lectureComponentId?: number;
  lecturePageId?: number;
  releaseDate?: string;
}

export enum PracticeRoomTab {
  MY_PRACTICE = 'my_practice',
  FEATURED = 'featured',
  GALLERY = 'gallery',
  ANALYTICS = 'analytics',
}

export enum SubmissionTab {
  COMMENTS = 'comments',
  AUTHOR_FEEDBACK = 'author_feedback',
  ALL_FEEDBACK = 'all_feedback',
  INSIGHTS = 'insights',
}

export enum StatType {
  PRACTICE = 'PRACTICE',
  FEATURED = 'FEATURED',
  COMMENT = 'COMMENT',
  LIKE = 'LIKE',
  VIEW = 'VIEW',
}

export enum SortOrFilterOption {
  RECENCY = 'recency',
  MY_COMMUNITY = 'my_community',
  POPULARITY = 'popularity',
  NO_ADMIN_COMMENTS = 'no_admin_comments',
}

export interface VideoPracticeLectureComponentType extends LectureComponent<ComponentType.VIDEO_PRACTICE> {
  activity: VideoPracticeActivity;
}

export type NVideoPracticeActivity = Normalized<VideoPracticeActivity, 'communications'>;

export interface VideoPracticeScenariosNormalized { [id: string]: VideoPracticeScenario }

export interface VideoPracticeActivitiesNormalized { [id: string]: NVideoPracticeActivity }

export interface VideoPracticeSubmissionsNormalized { [id: string]: VideoPracticeSubmission }

export interface PracticeSubmissionCommentsNormalized { [id: string]: PracticeSubmissionComment }

export interface PracticeSubmissionCommentsEntities {
  comments: PracticeSubmissionCommentsNormalized;
}

