import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { AnalyticsParams, QuestionSetRequest } from 'redux/schemas/api/analytics';
import { AnalyticsDashBoardType } from 'redux/schemas/app/analytics';

export const getAnalyticsToken = createAsyncThunk(
  'GET_ANALYTICS_TAGS',
  getToken,
);

async function getToken(params: AnalyticsParams) {
  const { type, catalogId, institutionId, journeyCatalogId, pageIdKey, questionSetId, scenarioId } = params;
  let tokenUrl = '';
  if (type === AnalyticsDashBoardType.COURSE || type === AnalyticsDashBoardType.COHORTS) {
    tokenUrl = `/analytics/${institutionId}/course_analytics_access/${catalogId}.json`;
  }
  if (type === AnalyticsDashBoardType.PRACTICE_ANALYTICS) {
    tokenUrl = `analytics/practice_scenarios/${scenarioId}.json`;
  }
  if (type === AnalyticsDashBoardType.LEARNING_JOURNEY) {
    tokenUrl = `analytics/${institutionId}/journey_analytics_access/${journeyCatalogId}`;
  }
  if (type === AnalyticsDashBoardType.INSTITUTION) {
    tokenUrl = `analytics/${institutionId}/org_analytics_access.json`;
  }
  if (type === AnalyticsDashBoardType.LICENSE) {
    tokenUrl = pageIdKey === 'main' ? `analytics/${institutionId}/license_analytics_access.json` : `analytics/${institutionId}/merged_license_analytics_access.json`;
  }
  if (type === AnalyticsDashBoardType.QUESTION_SET) {
    tokenUrl = `/analytics/${institutionId}/courses/${catalogId}/question_sets/${questionSetId}.json`;
  }
  return axios.get(tokenUrl).then((response) => response.data.result);
}

export const fetchQuestionSet = createAsyncThunk <any, QuestionSetRequest>(
  'FETCH_QUESTION_SET',
  async (params) => {
    const { institutionId, catalogId } = params;
    const response = await axios.get(`/analytics/${institutionId}/courses/${catalogId}/question_sets`);
    return response.data.result;
  },
);
