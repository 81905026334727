import React, { useState, useRef, useEffect, useMemo } from 'react';
import t from 'react-translate';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

// Schemas
import { PracticeAgain, PracticeRoomTab, SubmissionPrivacySetting } from 'redux/schemas/models/video-practice';

// Selectors
import { getScenario } from 'redux/selectors/video-practice';

// Hooks
import { makeQueryString } from 'shared/hooks/use-query';
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';


// Styles
import { screenXsMax } from 'styles/global_defaults/media-queries';

// Components
import { NvTab } from 'shared/components/nv-responsive-tabs';
import BackToTop from 'shared/components/back-to-top';
import GalleryTab from '../gallery/gallery-tab';
import FeaturedTab from '../featured/featured-tab';
import MyPracticeTab from '../my-practice/my-practice-tab';
import SubmissionOverview from '../submission-overview/submission-overview';
import AnalyticsTab from '../analytics/analytics-tab';
import { config } from '../../../../config/pendo.config.json';
import VideoPracticeOverview from './video-practice-overview';

const ScenarioOverview = () => {
  const history = useHistory();

  const {
    scenarioId,
    userId,
    selectedTab,
  } = useSelector((state) => state.app.practiceRoom.params);

  // Get scenario
  const scenario = useSelector((state) => getScenario(state, scenarioId));

  // Switch tab and set selected tab
  const switchTab = (tab: PracticeRoomTab) => {
    history.push(makeQueryString({
      selected: tab,
      galleryMode: true,
    }));

    setTimeout(() => {
      if (parentRef?.current) {
        parentRef?.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
    });
  };

  // Setting compact mode
  const [isCompactMode, setCompactMode] = useState<boolean>(false);
  const parentRef = useRef<HTMLInputElement>(null);

  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  // use header height based on the device.
  const nonCompactHeaderHeight = isHandheld ? 130 : 225;

  // Checking if the scroll has reached the header height from the top
  const isScrolledFromHeader = useInfiniteScroll(
    parentRef?.current,
    isCompactMode ? 80 : nonCompactHeaderHeight,
    false,
  );

  useEffect(() => {
    if (parentRef?.current) {
      setCompactMode(isScrolledFromHeader);
    }
  }, [parentRef, isScrolledFromHeader, setCompactMode]);

  const tabs: NvTab[] = [
    {
      text: t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.TABS.MY_PRACTICE(),
      onClick: () => switchTab(PracticeRoomTab.MY_PRACTICE),
      pendoTagName: config.pendo.practice.viewMyPractice,
    }, {
      text: t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.TABS.FEATURED(),
      onClick: () => switchTab(PracticeRoomTab.FEATURED),
      pendoTagName: config.pendo.practice.viewHighlightedTab,
    },
  ];
  if (scenario?.isPracticeAdmin || scenario?.privacy === SubmissionPrivacySetting.SHARED) {
    tabs.push({
      text: t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.TABS.GALLERY(),
      onClick: () => switchTab(PracticeRoomTab.GALLERY),
      pendoTagName: config.pendo.practice.viewPracticeGallery,
    });
  }

  const tabContent = useMemo(() => (
    <React.Fragment>
      <div style={{
        display: (userId && selectedTab === null)
        || (userId && (selectedTab === PracticeRoomTab.FEATURED
        || selectedTab === PracticeRoomTab.GALLERY
        )) ? 'block' : 'none',
      }}
      >
        {userId && <SubmissionOverview />}
      </div>
      <div style={{ display: selectedTab === PracticeRoomTab.MY_PRACTICE ? 'block' : 'none' }}>
        <MyPracticeTab />
      </div>
      <div style={{ display: selectedTab === PracticeRoomTab.GALLERY
        && !userId
        && (
          scenario?.isPracticeAdmin
        || scenario?.privacy === SubmissionPrivacySetting.SHARED
        ) ? 'block' : 'none' }}
      >
        <GalleryTab />
      </div>
      <div style={{ display: !userId && selectedTab === PracticeRoomTab.FEATURED ? 'block' : 'none' }}>
        <FeaturedTab />
      </div>
      <div style={{ display: selectedTab === PracticeRoomTab.ANALYTICS && !userId && scenario?.isPracticeAdmin ? 'block' : 'none' }}>
        <AnalyticsTab />
      </div>
      <BackToTop scrollBuffer={10} ref={parentRef} />
    </React.Fragment>
  ), [selectedTab, userId, scenario?.privacy, scenario?.isPracticeAdmin]);

  return (
    <VideoPracticeOverview showHeaderTabs isPracticeRoom>
      {tabContent}
    </VideoPracticeOverview>
  );
};

export default ScenarioOverview;
