export enum AnalyticsDashBoardType {
  UNSET = 'unset',
  LEARNING_JOURNEY = 'journey',
  INSTITUTION = 'institution',
  COURSE = 'course',
  LICENSE = 'license',
  COHORTS = 'cohort',
  PRACTICE_ANALYTICS = 'scenarioAnalytics',
  QUESTION_SET = 'questionSet',
}
